import React, { useEffect, useRef } from 'react';

const sketches = [
  ['01'],
  ['02_Boids', '02'],
  ['03_Tile', '03'],
  ['04'],
  ['05'],
  ['06'],
  ['07'],
  ['08'],
];

export const RandomSketch: React.FC = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const randomSketch = () => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      const iframeDocument = iframe.contentDocument;
      if (iframeDocument && iframeDocument.body) {
        const picked = sketches[Math.floor(Math.random() * sketches.length)];

        for (const sketch of picked) {
          const script = iframeDocument.createElement('script');
          script.async = false;
          script.setAttribute('src', `/sketches/${sketch}.js`);
          iframeDocument.body.appendChild(script);
        }
      }
    }
  };

  useEffect(() => {
    randomSketch();
  }, []);

  return (
    <iframe
      style={{
        width: '100%',
        height: '100%',
        border: 'none',
      }}
      srcDoc={iframeSrc}
      ref={iframeRef}
      onLoad={randomSketch}
      seamless
      sandbox='allow-scripts allow-same-origin'></iframe>
  );
};

const iframeSrc = `
<!DOCTYPE html>
<html>
<head>
<meta charset="utf-8" />
<meta name="viewport" content="width=device-width, initial-scale=1" />
<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
}
body {
    background: #fff;
}
canvas {
  background: #fff;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
</style>
<script src="https://cdn.jsdelivr.net/npm/p5/lib/p5.min.js"></script>
</head>
<body>
</body>
</html>
`;
